var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.showLegend)?_c('div',{staticClass:"progressometer-legend progressometer bottom-gutter"},[_vm._m(0)]):_vm._e(),_c('div',{staticClass:"progressometer"},_vm._l((_vm.stages),function(stage,index){return _c('div',{key:index,class:['progressometer__part', _vm.stageStatus(stage).status]},[_c('div',{staticClass:"progress__segment"},[_c('div',{staticClass:"segment__complete",style:({
            width: _vm.stageStatus(stage).completion + '%',
            backgroundColor: _vm.stageStatus(stage).color,
          })})]),_c('dl',{staticClass:"progress-list"},[_c('dt',{staticClass:"progress-list__heading h4 main-heading progress__segment__heading"},[_vm._v(" "+_vm._s(stage.name)+" ")]),_vm._l((stage.items),function(item,idx){return _c('dd',{key:idx,class:[
            'progress-list__item',
            {
              active: item.status === 'active',
              done: item.status === 'done',
              notStarted: item.status === 'notStarted' || !item.status,
            } ],style:(_vm.getTextStyle(item.status))},[_c('div',[(item.url)?_c('a',{attrs:{"href":item.url,"target":"_blank","rel":"noopener noreferrer"}},[_vm._v(" "+_vm._s(item.name)+" - "+_vm._s(item.status === "notStarted" ? "default" : item.status)+" "),(item.required === false)?_c('span',[_vm._v("(optional)")]):_vm._e()]):_c('span',[_vm._v(" "+_vm._s(item.name)+" - "+_vm._s(item.status === "notStarted" ? "default" : item.status)+" "),(item.required === false)?_c('span',[_vm._v("(optional)")]):_vm._e()]),(item.description)?_c('small',{staticClass:"item-hint"},[_vm._v(_vm._s(item.description))]):_vm._e()])])})],2)])}),0)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('dl',{staticClass:"progress-list-keys"},[_c('dt',{staticClass:"progress-list__heading"},[_vm._v("Legend")]),_c('dd',{staticClass:"progress-list__item notStarted"},[_vm._v("Not started (default)")]),_c('dd',{staticClass:"progress-list__item active"},[_vm._v("In progress (active)")]),_c('dd',{staticClass:"progress-list__item done"},[_vm._v("Done (done)")])])}]

export { render, staticRenderFns }