<template>
  <div>
    <div
      v-if="showLegend"
      class="progressometer-legend progressometer bottom-gutter"
    >
      <dl class="progress-list-keys">
        <dt class="progress-list__heading">Legend</dt>
        <dd class="progress-list__item notStarted">Not started (default)</dd>
        <dd class="progress-list__item active">In progress (active)</dd>
        <dd class="progress-list__item done">Done (done)</dd>
      </dl>
    </div>
    <div class="progressometer">
      <div
        v-for="(stage, index) in stages"
        :key="index"
        :class="['progressometer__part', stageStatus(stage).status]"
      >
        <div class="progress__segment">
          <div
            class="segment__complete"
            :style="{
              width: stageStatus(stage).completion + '%',
              backgroundColor: stageStatus(stage).color,
            }"
          ></div>
        </div>
        <dl class="progress-list">
          <dt
            class="progress-list__heading h4 main-heading progress__segment__heading"
          >
            {{ stage.name }}
          </dt>
          <dd
            v-for="(item, idx) in stage.items"
            :key="idx"
            :class="[
              'progress-list__item',
              {
                active: item.status === 'active',
                done: item.status === 'done',
                notStarted: item.status === 'notStarted' || !item.status,
              },
            ]"
            :style="getTextStyle(item.status)"
          >
            <div>
              <a
                v-if="item.url"
                :href="item.url"
                target="_blank"
                rel="noopener noreferrer"
              >
                {{ item.name }} -
                {{ item.status === "notStarted" ? "default" : item.status }}
                <span v-if="item.required === false">(optional)</span>
              </a>
              <span v-else>
                {{ item.name }} -
                {{ item.status === "notStarted" ? "default" : item.status }}
                <span v-if="item.required === false">(optional)</span>
              </span>
              <small v-if="item.description" class="item-hint">{{
                item.description
              }}</small>
            </div>
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import { Charts } from "@/components/Charts/config";

export default {
  name: "Progressometer",
  props: {
    stages: {
      type: Array,
      required: true,
    },
    styling: {
      type: Object,
      default: () => ({
        doneColor: Charts.colors.theme["success"],
        activeColor: Charts.colors.theme["warning"],
        notStartedColor: Charts.colors.theme["danger"],
        doneTextColor: "#b3b7c3",
        activeTextColor: Charts.colors.theme["warning"],
        notStartedTextColor: Charts.colors.theme["danger"],
      }),
    },
    showLegend: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    mergedStyling() {
      return {
        doneColor: this.styling.doneColor || Charts.colors.theme["success"],
        activeColor: this.styling.activeColor || Charts.colors.theme["warning"],
        notStartedColor:
          this.styling.notStartedColor || Charts.colors.theme["danger"],
        doneTextColor: this.styling.doneTextColor || "#b3b7c3",
        activeTextColor:
          this.styling.activeTextColor || Charts.colors.theme["warning"],
        notStartedTextColor:
          this.styling.notStartedTextColor || Charts.colors.theme["danger"],
        doneTextStyles: {
          fontWeight: this.styling.doneTextStyles?.fontWeight || "",
          fontSize: this.styling.doneTextStyles?.fontSize || "",
          fontFamily: this.styling.doneTextStyles?.fontFamily || "",
        },
        activeTextStyles: {
          fontWeight: this.styling.activeTextStyles?.fontWeight || "",
          fontSize: this.styling.activeTextStyles?.fontSize || "",
          fontFamily: this.styling.activeTextStyles?.fontFamily || "",
        },
        notStartedTextStyles: {
          fontWeight: this.styling.notStartedTextStyles?.fontWeight || "",
          fontSize: this.styling.notStartedTextStyles?.fontSize || "",
          fontFamily: this.styling.notStartedTextStyles?.fontFamily || "",
        },
      };
    },
    stageStatus() {
      return (stage) => {
        const requiredItems = stage.items.filter(
          (item) => item.required !== false
        );
        const total = requiredItems.length;
        const completed = requiredItems.filter(
          (item) => item.status === "done"
        ).length;
        const completion = (completed / total) * 100;

        const allDone = requiredItems.every((item) => item.status === "done");
        if (allDone) {
          return {
            status: "done",
            completion,
            color: this.mergedStyling.doneColor,
          };
        }

        const anyActive = requiredItems.some(
          (item) => item.status === "active"
        );
        if (anyActive) {
          return {
            status: "active",
            completion,
            color: this.mergedStyling.activeColor,
          };
        }

        return {
          status: "notStarted",
          completion,
          color: this.mergedStyling.notStartedColor,
        };
      };
    },
  },
  mounted() {
    // Apply colors from styling prop
    this.updateDoneColor(this.mergedStyling.doneColor);
    this.updateActiveColor(this.mergedStyling.activeColor);
    this.updateNotStartedColor(this.mergedStyling.notStartedColor);
    this.updateDoneTextColor(this.mergedStyling.doneTextColor);
    this.updateActiveTextColor(this.mergedStyling.activeTextColor);
    this.updateNotStartedTextColor(this.mergedStyling.notStartedTextColor);
  },
  methods: {
    updateDoneColor(color) {
      document.documentElement.style.setProperty("--done-color", color);
    },
    updateActiveColor(color) {
      document.documentElement.style.setProperty("--active-color", color);
    },
    updateNotStartedColor(color) {
      document.documentElement.style.setProperty("--notStarted-color", color);
    },
    updateDoneTextColor(color) {
      document.documentElement.style.setProperty("--done-text-color", color);
    },
    updateActiveTextColor(color) {
      document.documentElement.style.setProperty("--active-text-color", color);
    },
    updateNotStartedTextColor(color) {
      document.documentElement.style.setProperty(
        "--notStarted-text-color",
        color
      );
    },
    getTextStyle(status) {
      if (status === "done") {
        return {
          color: this.mergedStyling.doneTextColor,
          ...this.mergedStyling.doneTextStyles,
        };
      }
      if (status === "active") {
        return {
          color: this.mergedStyling.activeTextColor,
          ...this.mergedStyling.activeTextStyles,
        };
      }
      return {
        color: this.mergedStyling.notStartedTextColor,
        ...this.mergedStyling.notStartedTextStyles,
      };
    },
  },
};
</script>

<style scoped>
.item-hint {
  display: block;
  font-size: 0.8em;
  margin-top: 4px;
  font-weight: normal;
}
a {
  text-decoration: none !important;
}
a:hover {
  text-decoration: underline !important;
}

.progressometer {
  counter-reset: progress-segment-counter;
  display: flex;
  flex-direction: column;
}
@media (min-width: 1024px) {
  .progressometer {
    flex-direction: row;
  }
}
.progressometer__part {
  counter-increment: progress-segment-counter;
  flex-basis: 0;
  flex-grow: 1;
}
.progress__segment {
  align-items: center;
  background-color: #eff1f6;
  display: flex;
  height: 24px;
  margin-bottom: 12px;
  position: relative;
}
@media (max-width: 1023px) {
  .progress__segment {
    border-radius: 12px;
  }
}
.progress__segment:before {
  background-color: #eff1f6;
  border-radius: 50%;
  content: "";
  display: flex;
  height: 48px;
  transform: translateX(-6px);
  width: 48px;
}
.progress__segment:after {
  align-items: center;
  background-color: #b3b7c3;
  border-radius: 50%;
  color: #eff1f6;
  content: counter(progress-segment-counter);
  display: flex;
  font-weight: 900;
  height: 36px;
  justify-content: center;
  transform: translateX(-48px);
  transition: background-color 0.25s ease-out;
  width: 36px;
  z-index: 9;
}
.progressometer__part.active .progress__segment:after {
  background-color: var(--active-color);
  color: #fff;
}
.progressometer__part.done .progress__segment:after {
  background-color: var(--done-color);
  color: #fff;
}
.progressometer__part.notStarted .progress__segment:after {
  background-color: var(--notStarted-color);
  color: #fff;
}
.progressometer__part:first-of-type .progress__segment {
  border-radius: 12px 0 0 12px;
}
@media (max-width: 1023px) {
  .progressometer__part:first-of-type .progress__segment {
    border-radius: 12px;
  }
}
.progressometer__part:last-of-type .progress__segment {
  background-color: transparent;
}
.segment__complete {
  background-color: var(--done-color);
  border-radius: 3px;
  height: 6px;
  order: 3;
  position: absolute;
  transform: translateX(6px);
  transition: width 0.25s ease-out, background-color 0.25s ease-out;
  width: 0%;
  z-index: 9;
}
@media (max-width: 1023px) {
  .segment__complete {
    transform: translateX(0) scaleX(0.9);
  }
}
.progressometer__part.active .segment__complete {
  background-color: var(--active-color);
}
.progressometer__part.done .segment__complete {
  background-color: var(--done-color);
}
.progressometer__part.notStarted .segment__complete {
  background-color: var(--notStarted-color);
}
.progress-list {
  color: inherit;
  counter-reset: progress-step-counter;
  padding-left: 36px;
  padding-right: 24px;
  position: relative;
}
.progress-list:before {
  background-color: #eff1f6;
  border-radius: 3px;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  transform: translateX(-21px) translateY(-18px);
  width: 6px;
}
.progress-list__heading.progress-list__heading {
  font-weight: 900;
  margin: 0;
  padding: 16px 0 16px 8px;
}
.jumbotron h1.progress-list__heading,
.jumbotron h2.progress-list__heading,
.progress-list__heading.main-heading {
  position: relative;
}
.progress-list__heading.h4 {
  font-size: 22px;
}
.progress-list__heading.h5 {
  font-size: 18px;
}
.progress-list__heading.h6 {
  font-size: 14px;
}
.progress-list__item {
  align-items: center;
  color: #455565;
  counter-increment: progress-step-counter;
  display: flex;
  padding-left: 8px;
  position: relative;
}
.progress-list__item + .progress-list__item {
  margin-top: 16px;
}
.progress-list__item:before {
  align-items: center;
  background-color: #eff1f6;
  border-radius: 50%;
  box-shadow: 0 0 0 3px #eff1f6;
  content: counters(progress-segment-counter, ".") "."
    counters(progress-step-counter, ".");
  display: flex;
  height: 24px;
  justify-content: center;
  font-size: 12px;
  font-weight: 900;
  position: absolute;
  transform: translateX(-38px);
  width: 24px;
  color: var(--notStarted-text-color, #455565);
}
.progress__segment__heading:before {
  background-color: #eff1f6;
  border-radius: 50%;
  content: "";
  height: 16px;
  left: 0;
  position: absolute;
  top: calc(50% - 10px);
  transform: translate(-26px, 0);
  width: 16px;
}
.progressometer__part.active .progress__segment__heading {
  color: var(--active-color);
}
.progressometer__part.active .progress-list__heading {
  color: var(--active-color);
}
.progressometer__part.done .progress-list__heading {
  color: var(--done-color);
}
.progressometer__part.notStarted .progress-list__heading {
  color: var(--notStarted-color);
}
.progress-list__item.active {
  color: var(--active-text-color);
  font-weight: 900;
}
.progress-list__item.active:before {
  color: var(--active-color);
}
.progress-list__item.notStarted:before {
  color: var(--notStarted-color);
}
.progress-list__item.done {
  color: var(--done-text-color, #b3b7c3);
}
.progress-list__item.done:before {
  background-color: var(--done-color);
  color: #fff;
  content: "✓";
  font-family: fontawesome;
  font-size: 1em;
  font-weight: 300;
}
.progress-list__item.notStarted {
  color: var(--notStarted-text-color, #455565);
}
.progress-list__item a {
  color: inherit;
  text-decoration: underline;
}
.progressometer--ext {
  justify-content: inherit;
}
.progressometer--ext .progressometer__part.done .progress__segment:after,
.progressometer--ext .progressometer__part.done .segment__complete {
  background-color: var(--done-color);
}
.progressometer--ext .progressometer__part.notStarted .progress__segment:after,
.progressometer--ext .progressometer__part.notStarted .segment__complete {
  background-color: var(--notStarted-color);
}
.progressometer--ext .progress-list__item {
  align-items: flex-start;
  color: #455565;
  height: auto;
  min-height: 2.2em;
}
.progressometer--ext .progress-list__item:before {
  transform: translateX(-40px) translateY(-0.2em);
}
.progressometer--ext .progress-list__item:last-child:before {
  z-index: 9;
}
.progressometer--ext .progress-list__item:last-child:after {
  background-color: #fff;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  transform: translateX(-30px);
  width: 8px;
  z-index: 1;
}
.panel .progressometer--ext .progress-list__item:last-child:after {
  background-color: #fff;
}
.progressometer--ext .progress-list__item .item__sub-list {
  margin-bottom: 0;
}
.progressometer--ext .progress-list__item .item__sub-list dt {
  color: inherit;
  font-size: 1em;
}
.progressometer--ext .progress-list__item .item__sub-list dd {
  color: inherit;
  font-size: 12px;
  margin: 8px 0;
  padding-left: 24px;
  position: relative;
}
.progressometer--ext .progress-list__item.done:before {
  border: 1px solid var(--done-color);
  box-shadow: 0 0 0 3px #eff1f6;
  content: "✓";
  font-family: fontawesome;
  font-size: 1em;
  font-weight: 300;
  height: 24px;
  line-height: 1em;
  margin: 2px;
  text-indent: -1px;
  width: 24px;
}
.progressometer--ext .progress-list__item.active:before {
  color: var(--active-text-color);
}
.progressometer--ext .progress-list__item.notStarted:before {
  color: var(--notStarted-text-color, #455565);
}
.progressometer--ext .progress-sub-list__item:before {
  border: 1px solid transparent;
  border-radius: 50%;
  content: "";
  font-family: fontawesome;
  font-size: 0.8em;
  font-weight: 300;
  height: 16px;
  line-height: 1.6em;
  position: absolute;
  text-align: center;
  transform: translate(-20px, 1px);
  vertical-align: middle;
  width: 16px;
}
.progressometer--ext .progress-sub-list__item.active:before {
  color: var(--active-text-color);
}
.progressometer--ext .progress-sub-list__item.done:before {
  border-color: var(--done-color);
  content: "✓";
}
.progressometer--ext .progress-sub-list__item.done:before {
  background-color: var(--done-color);
  color: #fff;
}
.progressometer--ext .progress-sub-list__item.notStarted:before {
  color: var(--notStarted-color);
}
.progressometer--ext a.progress-list-item__link {
  color: inherit;
  text-decoration: underline;
}
.progressometer--ext .progress-list-keys .progress-list__item.active {
  font-weight: 300;
}
.progress-list-keys {
  align-items: center;
  background-color: #fff;
  border: 1px solid #e0e3ee;
  border-radius: 4px;
  display: inline-flex;
  margin: 0;
}
.progress-list-keys .progress-list__heading {
  background-color: #eff1f6;
  border-right: 1px solid #e0e3ee;
  font-size: 14px;
  font-weight: 300;
  margin: 0;
  margin-right: 16px;
  padding: 16px 24px;
  color: #606f7d;
}
.progress-list-keys .progress-list__item {
  align-items: center;
  padding: 16px 16px 16px 32px;
}
.progress-list-keys .progress-list__item.done:before {
  box-shadow: none;
  margin: 0;
}

.progress-list-keys .progress-list__item + .progress-list__item {
  margin-top: 0;
}
.progress-list-keys .progress-list__item:before {
  transform: translateX(-32px) translateY(0);
}
.progress-list-keys .progress-list__item:last-child:after {
  content: none;
}
</style>
