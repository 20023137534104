<template>
  <div>
    <div class="header pb-6 d-flex align-items-center">
      <!-- Mask -->
      <span class="mask bg-white opacity-8"></span>
      <!-- Header container -->
      <b-container fluid class="d-flex">
        <b-row>
          <b-col sm="12" md="auto" class="mt-2">
            <h1 class="text-primary display-1">Timeline</h1>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="px-6 mt-0">
      <Progressometer
        class="full-width"
        :styling="styling"
        :stages="stages"
        :showLegend="false"
      />
    </div>
  </div>
</template>

<script>
// Components
import Progressometer from "@/views/Components/Progressometer.vue";

export default {
  name: "Dev",
  components: {
    Progressometer,
  },
  data() {
    return {
      // styling: {
      //   doneColor: "#6c757d",
      //   activeColor: "#28a745",
      //   notStartedColor: "#007bff",
      //   doneTextColor: "#ff6347",
      //   activeTextColor: "#ffbf00",
      //   notStartedTextColor: "#17a2b8",
      //   doneTextStyles: {
      //     fontWeight: "normal",
      //     fontSize: "16px",
      //     fontFamily: "Verdana, sans-serif",
      //   },
      //   activeTextStyles: {
      //     fontWeight: "bold",
      //     fontSize: "12px",
      //     fontFamily: "Times New Roman, serif",
      //   },
      //   notStartedTextStyles: {
      //     fontWeight: "bold",
      //     fontSize: "20px",
      //     fontFamily: "Courier New, monospace",
      //   },
      // },
      // styling: {
      //   doneColor: "#e4498e",
      //   activeColor: "#00bcd4",
      //   notStartedColor: "#a7c8d5",
      //   activeTextColor: "#00bcd4",
      // },
      stages: [
        {
          name: "Part 1",
          items: [
            {
              name: "Action item 1",
              status: "done",
              url: "https://example.com/item1",
              required: true,
              description: "This is the description for action item 1.",
            },
            {
              name: "Action item 2",
              status: "done",
              required: true,
            },
            {
              name: "Action item 3",
              status: "done",
              url: "https://example.com/item3",
              description: "This is the description for action item 3.",
            },
            {
              name: "Action item 4",
              status: "done",
              description: "This is the description for action item 4.",
            },
          ],
        },
        {
          name: "Part 2",
          items: [
            {
              name: "Action item 1",
              status: "done",
              description:
                "This is the description for action item 1 in part 2.",
            },
            {
              name: "Action item 2",
              status: "active",
              required: false,
              description:
                "This is the description for action item 2 in part 2.",
            },
            {
              name: "Action item 3",
              status: "active",
              url: "https://example.com/item3",
              description:
                "This is the description for action item 3 in part 2.",
            },
            {
              name: "Action item 4",
              status: "notStarted",
            },
          ],
        },
        {
          name: "Part 3",
          items: [
            {
              name: "Action item 1",
              status: "notStarted",
              description:
                "This is the description for action item 1 in part 3.",
            },
            {
              name: "Action item 2",
              status: "notStarted",
            },
          ],
        },
        // {
        //   name: "Part 4",
        //   items: [
        //     {
        //       name: "Action item 1",
        //       status: "notStarted",
        //       url: "https://example.com/item1",
        //       required: true,
        //       description:
        //         "This is the description for action item 1 in part 4.",
        //     },
        //     {
        //       name: "Action item 2",
        //       status: "notStarted",
        //       required: true,
        //       description:
        //         "This is the description for action item 2 in part 4.",
        //     },
        //     {
        //       name: "Action item 3",
        //       status: "notStarted",
        //       url: "https://example.com/item3",
        //       description:
        //         "This is the description for action item 3 in part 4.",
        //     },
        //     {
        //       name: "Action item 4",
        //       status: "notStarted",
        //       description:
        //         "This is the description for action item 4 in part 4.",
        //     },
        //   ],
        // },
      ],
    };
  },
};
</script>

<style scoped>
.full-width {
  width: 100%;
}
.header {
  padding-bottom: 6rem;
  display: flex;
  align-items: center;
}
.mask {
  background-color: white;
  opacity: 0.8;
}
</style>
